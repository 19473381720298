import { defineStore } from "pinia";
import { AlertType } from "types";
import { ref } from "vue";

export const useAlertsStore = defineStore("alerts", () => {
  const alerts = ref([]);

  function add(type: AlertType, message: string) {
    alerts.value.push({
      id: alerts.value.length,
      type: type,
      message: message,
    });
  }

  function remove(id: number) {
    // Find and remove alert
    for (let i = 0; i < alerts.value.length; i++) {
      if (alerts.value[i].id === id) {
        alerts.value.splice(i, 1);
        break;
      }
    }

    // Renumber alerts
    for (let i = 0; i < alerts.value.length; i++) {
      alerts.value[i].id = i;
    }
  }

  return { alerts, add, remove };
});
