import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@/guards/auth";
declare module "vue-router" {
  interface RouteMeta {
    label?: string;
    tabName?: string;
    optionName?: string;
  }
}

const Auth0Invite = () => import("@/views/Auth0Invite.vue");
const Auth0Complete = () => import("@/views/Auth0Complete.vue");
const Logout = () => import("@/views/Logout.vue");
const LogoutComplete = () => import("@/views/LogoutComplete.vue");
const Unauthorized = () => import("@/views/Unauthorized.vue");
const PageNotFound = () => import("@/views/PageNotFound.vue");
const Policy = () => import("@/views/Policy.vue");

const Layout = () => import("@/views/Layout.vue");
const Dashboard = () => import("@/views/Dashboard.vue");
const Leaderboard = () => import("@/views/Leaderboard.vue");
const StationList = () => import("@/views/StationList.vue");
const StationManage = () => import("@/views/StationManage.vue");
const SettingsAccessKeys = () => import("@/views/SettingsAccessKeys.vue");
const SettingsUsers = () => import("@/views/SettingsUsers.vue");
const DeviceDashboard = () => import("@/views/DeviceDashboard.vue");

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: { name: "dashboard" },
    },
    {
      path: "/dashboard",
      redirect: { name: "dashboard" },
    },
    {
      path: "/station_manager",
      redirect: { name: "dashboard" },
    },
    {
      path: "/leaderboard",
      redirect: { name: "leaderboard" },
    },
    {
      path: "/status",
      redirect: { name: "stations" },
    },
    {
      path: "/device-dashboard",
      redirect: { name: "device_dashboard" },
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: Unauthorized,
    },
    {
      path: "/404",
      name: "404",
      component: PageNotFound,
    },
    {
      path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
      redirect: { name: "404" },
    },
    {
      path: "/auth0/invite", // Used for accepting organization invite
      name: "invite_auth0",
      component: Auth0Invite,
    },
    {
      path: "/invitation", // Used for accepting organization invite
      name: "invitation",
      component: Auth0Invite,
    },
    {
      path: "/auth0/complete", // Used after auth0 completion
      name: "complete_auth0",
      component: Auth0Complete,
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
      meta: {
        optionName: "Log out",
      },
    },
    {
      path: "/logout/complete",
      name: "logout_complete",
      component: LogoutComplete,
    },
    {
      path: "/policy",
      name: "policy",
      component: Policy,
    },
    {
      path: "/app",
      component: Layout,
      children: [
        {
          path: "",
          name: "app",
          redirect: { name: "dashboard" },
        },
        {
          path: "dashboard",
          name: "dashboard",
          component: Dashboard,
          beforeEnter: authGuard,
          meta: {
            label: "Method Dashboard",
            tabName: "Dashboard",
          },
        },
          {
            path: "device-dashboard",
            name: "device_dashboard",
            component: DeviceDashboard,
            beforeEnter: authGuard,
            meta: {
                optionName: "Device Dashboard",
                permissions: ["read:device-dashboard"],
            },
        },
        {
          path: "stations",
          name: "station_list",
          component: StationList,
          beforeEnter: authGuard,
          meta: {
            label: "Stations",
            tabName: "Stations",
          },
        },
        {
          path: "stations/:stationId",
          name: "station_manage",
          component: StationManage,
          beforeEnter: authGuard,
          props: true,
        },
        {
          path: "leaderboard",
          name: "leaderboard",
          component: Leaderboard,
          beforeEnter: authGuard,
          meta: {
            label: "Method Leaderboard",
            tabName: "Leaderboard",
          },
        },
        {
          path: "access-keys",
          name: "access_keys",
          component: SettingsAccessKeys,
          beforeEnter: authGuard,
          meta: {
            optionName: "API Access Keys",
            permissions: ["read:access-keys"],
          },
        },
        {
          path: "users",
          name: "users",
          component: SettingsUsers,
          beforeEnter: authGuard,
          meta: {
            optionName: "Users",
            permissions: ["read:organizations.members"],
          },
        },
      ],
    },
  ],
});

export default router;
