import { unref } from "vue";
import { watchEffectOnceAsync } from "utility";
import { useAuthStore } from "@/stores/auth";

export async function authGuard(to) {
  const auth = useAuthStore();

  const fn = async () => {
    if (!auth.authenticated) {
      await auth.checkToken(to);
    }

    return auth.canNavigate(to);
  };

  if (!unref(auth.loading)) {
    return fn();
  }

  await watchEffectOnceAsync(() => !unref(auth.loading));

  return fn();
}
