import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";

import "./assets/main.css";
import "v-calendar/dist/style.css";

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(VueApexCharts);


Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  enabled: import.meta.env.VITE_ENVIRONMENT !== "development",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [import.meta.env.VITE_APP_URL_DASHBOARD],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  //replaysSessionSampleRate: 1.0,
  //replaysOnErrorSampleRate: 1.0,
});

app.mount("#app");
