import { createAuth0Client } from "@auth0/auth0-spa-js";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

export const useAuthStore = defineStore("auth", () => {
  const router = useRouter();

  const _client = ref();
  const _state = ref();
  const _claims = ref();
  const _permissions = ref();

  const loading = ref(true);
  const checkingToken = ref(false);
  const authenticated = ref(false);
  const token = ref();
  const availableOrganizations = ref();
  const selectedOrganizationId = ref();
  const user = ref();

  function resetAuthState() {
    _state.value = null;
    _claims.value = null;

    checkingToken.value = false;
    authenticated.value = false;
    token.value = null;
    selectedOrganizationId.value = null;
    user.value = null;
  }

  /**
   *  Creates the Auth0 client using the single page application SDK
   */
  async function init() {
    loading.value = true;
    resetAuthState();

    try {
      _client.value = await createAuth0Client({
        domain: import.meta.env.VITE_AUTH0_DOMAIN,
        clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          redirect_uri:
            window.location.origin + import.meta.env.VITE_AUTH0_CALLBACK_ROUTE,
        },
      });
    } catch (error) {
      _handleAuthError(error);
    } finally {
      loading.value = false;
    }
  }

  function canNavigate(to) {
    if (to.meta?.hasOwnProperty("permissions")) {
      for (const permission of to.meta.permissions) {
        if (!_permissions.value.includes(permission)) {
          return false;
        }
      }
      return authenticated.value;
    } else {
      return authenticated.value;
    }
  }

  /**
   * Called by the authentication guard when routing to any page that uses data.
   * It will ensure their token is scoped to an avialble organization.
   */
  async function checkToken(to) {
    authenticated.value = false;
    checkingToken.value = true;

    try {
      // Catch failed login attempt and send them to the login flow
      try {
        token.value = await _client.value.getTokenSilently();
        _claims.value = await _client.value.getIdTokenClaims();
        _permissions.value = await _parsePermissions(token.value);
      } catch (error) {
        await loginWithRedirect({
          appState: { target: to.fullPath },
        });
        checkingToken.value = false;
        return;
      }

      // Check if token can access an available organization
      let canAccessOrganization = false;
      if (_claims.value.hasOwnProperty("org_id")) {
        selectedOrganizationId.value = _claims.value.org_id;
        canAccessOrganization = true;
      }

      // If the token cannot access an organization then create a token that can
      if (!canAccessOrganization) {
        if (_claims.value.hasOwnProperty("method_auth0_org_id")) {
          token.value = await _client.value.getTokenSilently({
            cacheMode: "off", // Force token refresh
            authorizationParams: {
              organization: _claims.value["method_auth0_org_id"],
            },
          });
          _claims.value = await _client.value.getIdTokenClaims();
          _permissions.value = await _parsePermissions(token.value);
          if (_claims.value.hasOwnProperty("org_id")) {
            selectedOrganizationId.value = _claims.value.org_id;
            canAccessOrganization = true;
          }
        }
      }

      if (!canAccessOrganization) {
        throw new Error("You must belong to at least one organization");
      }

      // Load user data
      user.value = await _client.value.getUser();
      authenticated.value = true;
    } catch (error) {
      _handleAuthError(error);
    } finally {
      checkingToken.value = false;
    }
  }

  /**
   * Silently refreshes the access token with the new organization.
   * It will then reload the application to make sure all data loaded
   * belongs to the new organization. The token from the first step will
   * be picked up by the client after the page reloads.
   */
  async function swapOrganization(organization) {
    if (organization.id === selectedOrganizationId.value) return;

    checkingToken.value = true;

    try {
      await _client.value.getTokenSilently({
        cacheMode: "off", // Force token refresh
        authorizationParams: {
          organization: organization.auth0_id,
        },
      });

      // Reload page to clear stores
      window.location.reload();
    } catch (error) {
      _handleAuthError(error);
    } finally {
      checkingToken.value = false;
    }
  }

  /**
   *  Gets the user to login through Auth0 universal login page
   */
  async function loginWithRedirect(options?) {
    try {
      await _client.value.loginWithRedirect(options);
    } catch (error) {
      _handleAuthError(error);
    }
  }

  /**
   *  Parses the state after a successful login. This state holds the page
   *  the user intended to visit before being redirected to Auth0 login
   */
  async function handleRedirectCallback() {
    try {
      const state = await _client.value.handleRedirectCallback();
      const target = state.appState?.target ? state.appState.target : "/";
      router.push(target);
    } catch (error) {
      _handleAuthError(error);
    }
  }

  /**
   *  Logs the user out and sends them to a the provided route e.g /logout/complete
   */
  async function logout(returnTo?: string) {
    try {
      resetAuthState();
      await _client.value.logout({
        logoutParams: {
          returnTo: window.location.origin + (returnTo ? returnTo : ""),
        },
      });
    } catch (error) {
      _handleAuthError(error);
    }
  }

  /**
   *  Retireve the permissions from a token
   */
  async function _parsePermissions(token) {
    if (!token) return;

    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const tokenBody = JSON.parse(jsonPayload);
    return tokenBody?.permissions;
  }

  /**
   *  Global handler for all errors in the authentication store.
   */
  async function _handleAuthError(error: Error) {
    console.log(error.message);

    resetAuthState();
    router.push({
      name: "unauthorized",
      query: {
        title: "Unauthorized",
        message: "You do not have access to Method Insight",
      },
    });
  }

  return {
    loading,
    checkingToken,
    authenticated,
    token,
    availableOrganizations,
    selectedOrganizationId,
    user,
    init,
    canNavigate,
    checkToken,
    swapOrganization,
    loginWithRedirect,
    handleRedirectCallback,
    logout,
  };
});
