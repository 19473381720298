<script setup lang="ts">
import { Alert, Spinner } from "ui";
import { onMounted } from "vue";
import { RouterView } from "vue-router";
import { useAlertsStore } from "./stores/alerts";
import { useAuthStore } from "./stores/auth";
import { usePreferencesStore } from "./stores/preferences";
import { SpeedInsights } from "@vercel/speed-insights/vue"
import { inject } from "@vercel/analytics"
inject();

const auth = useAuthStore();
const alerts = useAlertsStore();
const preferences = usePreferencesStore();

onMounted(async () => {
  preferences.init();
  await auth.init();
});
</script>

<template>
  <SpeedInsights />
  <div class="h-screen w-screen">
    <div class="flex w-full justify-center">
      <TransitionGroup
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <Alert
          class="max-w-lg"
          v-for="alert in alerts.alerts"
          :key="alert.id"
          :alert-type="alert.type"
          @dismiss="alerts.remove(alert.id)"
        >
          {{ alert.message }}
        </Alert>
      </TransitionGroup>
    </div>

    <div
      v-if="auth.loading || auth.checkingToken"
      class="absolute top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-flat-50 opacity-80 dark:bg-flat-700"
    >
      <Spinner size="xl" />
    </div>

    <!-- Router view needs to show to trigger token check with authentication route guard -->
    <RouterView v-if="!auth.loading" />
  </div>
</template>
