import { computed, watchEffect } from "vue";

export function useModelWrapper(props: any, emit: any, name = "modelValue") {
  return computed({
    get: () => props[name],
    set: (value) => emit(`update:${name}`, value),
  });
}

/**
 * Run watchEffect untill the watcher returns true, then stop the watch.
 * Once it returns true, the promise will resolve.
 */
export function watchEffectOnceAsync<T>(watcher: () => T) {
  return new Promise<void>((resolve) => {
    watchEffectOnce(watcher, resolve);
  });
}

/**
 * Run watchEffect untill the watcher returns true, then stop the watch.
 * Once it returns true, it will call the provided function.
 */
export function watchEffectOnce<T>(watcher: () => T, fn: Function) {
  const stopWatch = watchEffect(() => {
    if (watcher()) {
      fn();
      stopWatch();
    }
  });
}

export function deepCopy(object: Object) {
  try {
    const copy = JSON.stringify(object);
    return JSON.parse(copy);
  } catch (error) {
    return null;
  }
}

export function sortArray(
  array: any[],
  sortProperty: string,
  secondarySortProperty?: string,
  sortDesc: boolean = true
) {
  array.sort((a, b) => {
    if (a.hasOwnProperty(sortProperty) && b.hasOwnProperty(sortProperty)) {
      if (a[sortProperty] < b[sortProperty]) {
        return sortDesc ? -1 : 1;
      }

      if (a[sortProperty] > b[sortProperty]) {
        return sortDesc ? 1 : -1;
      }
    }

    if (
      secondarySortProperty &&
      a.hasOwnProperty(secondarySortProperty) &&
      b.hasOwnProperty(secondarySortProperty)
    ) {
      if (
        secondarySortProperty &&
        a[secondarySortProperty] < b[secondarySortProperty]
      ) {
        return sortDesc ? -1 : 1;
      }

      if (
        secondarySortProperty &&
        a[secondarySortProperty] > b[secondarySortProperty]
      ) {
        return sortDesc ? 1 : -1;
      }
    }

    return 0;
  });
}

export function displayWeight(weight: number, displayChange: boolean = false) {
  if (weight === null || weight === undefined) return "";

  const roundedWeight = Math.round(weight * 10) / 10; // rounds to 1 dp
  const changeSymbol = roundedWeight < 0 ? "-" : "+";

  if (displayChange && Math.abs(roundedWeight) >= 0.1) {
    return changeSymbol + " " + Math.abs(roundedWeight).toFixed(1) + " kg";
  } else if (displayChange) {
    return changeSymbol + " <100g";
  }

  return roundedWeight >= 0.1 ? roundedWeight.toFixed(1) + " kg" : "<100g";
}

export function displayPercentage(
  percentage: number,
  displayChange: boolean = false
) {
  if (percentage === null || percentage === undefined) return "";

  percentage = percentage * 100; // convert deciaml to percentage

  const roundedPercentage = Math.round(percentage * 10) / 10; // rounds to 1 dp
  const changeSymbol = roundedPercentage < 0 ? "-" : "+";

  if (displayChange) {
    return changeSymbol + " " + Math.abs(roundedPercentage).toFixed(1) + "%";
  }

  return roundedPercentage.toFixed(1) + "%";
}
